<script setup lang="ts">
import { strLimitLength } from 'dvn-js-utils'
import type { VideoPlayerData } from '~~/types'

/*
VIDEOS:
embeded classic url:    https://www.youtube.com/embed/UsFCsRbYDyA?si=ZaB79-jFk8gQupMF&amp;start=375
embeded no-cookie url:  https://www.youtube-nocookie.com/embed/UsFCsRbYDyA?si=ZaB79-jFk8gQupMF&amp;start=375

PLAYLISTS:
embeded classic url:    https://www.youtube.com/embed/videoseries?si=FoDXO-xd78g2KdB4&amp;list=PLirh7S2cI3fgQmfussuTgAax_YlQ9GgLx
embeded no-cookie url:  https://www.youtube-nocookie.com/embed/videoseries?si=FoDXO-xd78g2KdB4&amp;list=PLirh7S2cI3fgQmfussuTgAax_YlQ9GgLx

PARAMETERS:
see: https://freshysites.com/web-design-development/how-to-use-youtube-parameters-and-recent-changes/
*/

const props = withDefaults(
  defineProps<{
    modelValue: VideoPlayerData | undefined
    title?: string
    noCookie?: boolean
    autoplay?: boolean
    hideLogo?: boolean
    suggestions?: boolean
    controls?: boolean
    showinfo?: boolean
  }>(),
  {
    title: 'DiViNe Video Player',
    noCookie: false,
    autoplay: true,
    hideLogo: false,
    suggestions: true,
    controls: true,
    showinfo: true,
  },
)

const userStore = useUserStore()
const { t } = useI18n()

const emit = defineEmits<{
  (e: 'update:modelValue', value: VideoPlayerData | undefined): void
}>()

const video = computed({
  get() {
    return props.modelValue
  },
  set(v) {
    emit('update:modelValue', v)
  },
})

const url = computed(() => {
  const qs = '&amp;'
  const v = video.value
  if (v) {
    let params = ''
    if (v.type === 'playlist') params = `videoseries?list=${v.id}` + qs
    if (v.type === 'video') params = `${v.id}?`

    if (v.time) params += `start=${v.time}` + qs
    if (props.autoplay) params += `autoplay=1` + qs
    if (props.hideLogo) params += `modestbranding=1` + qs
    if (!props.suggestions) params += `rel=0` + qs
    if (!props.controls) params += `controls=0` + qs
    if (!props.showinfo) params += `controls=0` + qs

    if (props.noCookie) return `https://www.youtube-nocookie.com/embed/${params}`
    else return `https://www.youtube.com/embed/${params}}`
  }
  return undefined
})

const visible = computed(() => {
  return !!video.value
})

const width = computed(() => {
  if (import.meta.client) {
    const w = window.innerWidth
    if (w <= 500) return 350
    if (w <= 800) return 420
    return 750
  }
  return undefined
})

const height = computed(() => {
  if (import.meta.client) {
    const w = window.innerWidth
    if (w <= 500) return 350
    if (w <= 800) return 420
    return 500
  }
  return undefined
})

const title = computed(() => {
  if (import.meta.client) {
    const w = window.innerWidth
    if (w <= 500) return strLimitLength(props.title, 22)
    if (w <= 800) return strLimitLength(props.title, 30)
    return strLimitLength(props.title, 50)
  }
  return undefined
})

const isFavorite = computed(() => {
  if (video.value && video.value.type === 'video') {
    return userStore.isFavoriteVideo(video.value.id)
  }
  return undefined
})

const isSeen = computed(() => {
  if (video.value && video.value.type === 'video') {
    return userStore.isSeenVideo(video.value.id)
  }
  return undefined
})

const favoriteIconText = computed(() => {
  if (isFavorite.value) return t('VideoPlayer.favorite-on')
  else return t('VideoPlayer.favorite-off')
})

const seenIconText = computed(() => {
  if (isSeen.value) return t('VideoPlayer.seen-on')
  else return t('VideoPlayer.seen-off')
})

async function updateSeenVideo() {
  if (video.value && video.value.type === 'video') {
    await userStore.addRemoveSeenVideo(video.value.id)
  }
}

async function updateFavoriteVideo() {
  if (video.value && video.value.type === 'video') {
    await userStore.addRemoveFavoriteVideo(video.value.id)
  }
}

function close() {
  video.value = undefined
}
</script>

<template>
  <VDialog v-model="visible" persistent :max-width="width">
    <!-- 👉 Video Popup -->
    <div v-if="url" class="dvn-videoplayer-box">
      <div class="dvn-videoplayer-topbar">
        <div class="ml-2 dvn-videoplayer-topbar-title">
          {{ title }}
        </div>

        <div class="dvn-videoplayer-topbar-icons">
          <VBtn
            v-if="userStore.isAuthenticated"
            icon
            size="32"
            variant="text"
            color="black"
            class="ma-1 dvn-videoplayer-toolbar-button"
            @click="updateSeenVideo"
          >
            <VIcon v-if="!isSeen" icon="mdi-check-outline" />
            <VIcon v-else icon="mdi-check-bold" />
            <VTooltip activator="parent" location="top" :text="seenIconText" />
          </VBtn>
          <VBtn
            v-if="userStore.isAuthenticated"
            icon
            size="32"
            variant="text"
            color="black"
            class="ma-1 dvn-videoplayer-toolbar-button"
            @click="updateFavoriteVideo"
          >
            <VIcon v-if="!isFavorite" icon="mdi-heart-outline" />
            <VIcon v-else icon="mdi-heart" />
            <VTooltip activator="parent" location="top" :text="favoriteIconText" />
          </VBtn>

          <VBtn
            icon
            size="32"
            variant="text"
            color="black"
            class="ma-1 dvn-videoplayer-toolbar-button"
            @click="close"
          >
            <VIcon icon="mdi-close" />
            <VTooltip activator="parent" location="top" :text="$t('VideoPlayer.close')" />
          </VBtn>
        </div>
      </div>

      <iframe
        width="100%"
        :height="height"
        :src="url"
        :title="props.title"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  </VDialog>
</template>

<style lang="css" scoped>
div.dvn-videoplayer-box {
  display: flex;
  flex-direction: column;
  background-color: var(--dvn-color-secondary);
  border: 2px solid var(--dvn-color-secondary);
  padding: 0;
}
div.dvn-videoplayer-topbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
div.dvn-videoplayer-topbar-title {
  color: black;
  font-size: 1em;
  font-weight: 600;
}
div.dvn-videoplayer-topbar-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dvn-videoplayer-toolbar-button:hover {
  color: var(--dvn-color-text) !important;
  -webkit-filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 1));
  filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 1));
}
</style>
